@import '@crystal-eyes/constants.scss';

.navBar {
  align-items: center;
  justify-content: center;
  font-size: 0.85rem;
  box-sizing: border-box;
  max-width: 100vw;
  min-width: 100%;
  @media print {
    display: none;
  }

  background-color: white;

  a {
    cursor: pointer;
    text-decoration: none;
  }

  :global(.nav-areas) {
    position: relative;
    width: min(100%, $main-content-width);
    margin: auto auto;

    @media (max-width: $mobile-width) {
      position: static;
      margin: 0;
      width: 100%;
    }
  }

  :global(.primary-bar) {
    width: 100%;
    min-height: $nav-height;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: $nav-height auto;
    column-gap: 1.5rem;
    box-sizing: border-box;
    align-items: center;

    :global(.mobile-more-heading) {
      display: none;
    }

    @media (max-width: $mobile-width) {
      grid-template-columns: 1fr 0 $mobile-more-size;
      column-gap: 0;
      padding: 0 20px;

      :global(.notif-button) {
        display: none;
      }

      :global(.more-button) {
        svg {
          display: none;
        }
      }
    }
  }

  :global(.primary-bar.public) {
    @media (max-width: $mobile-width) {
      padding: 0 20px;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      padding: 12px;

      :global(.logo-search-area) {
        justify-content: center;
        margin-bottom: 12px;
      }
    }
  }

  :global(.primary-bar .svg-button) {
    &:hover {
      color: black;

      svg {
        stroke: black;
      }
    }

    & > svg {
      width: 2em;
      height: 2em;
      cursor: pointer;
      stroke: #aaa;
    }
  }

  :global(.primary-bar .mobile-more-button) {
    display: none;

    svg {
      width: $mobile-more-size;
      height: $mobile-more-size;
    }

    @media (max-width: $mobile-width) {
      display: block;
    }
  }

  :global(.primary-bar .logo-search-area) {
    display: flex;
    align-items: center;
    column-gap: 0.9rem;

    a {
      line-height: 0;
    }

    img {
      object-fit: contain;
    }

    object {
      width: 128px;
    }

    :global(.logo-full) {
      display: inline-block;

      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    :global(.logo-minimal) {
      display: none;

      @media (max-width: $mobile-width) {
        display: inline-block;
      }
    }

    :global(.search-area) {
      width: 100%;
      max-width: 287px;

      @media (max-width: $mobile-width) {
        display: none;
      }
    }

    :global(.powered-by) {
      color: #01293d;
      font-style: italic;
      font-size: 0.63rem;
    }

    :global(.white-label-area) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      line-height: 1rem;

      & > span {
        font-size: 0.6rem;
      }
    }
  }

  :global(.primary-bar.mobile-open .full-section-items) {
    @media (max-width: $mobile-width) {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: white;
      overflow-y: scroll;
      z-index: 100;

      :global(.mobile-close-link) {
        display: block;
        position: fixed;
        right: 24px;
        top: 24px;
        font-size: 2rem;
        z-index: 1000;
      }

      :global(.mobile-more-heading) {
        display: block;
      }

      :global(.special-options) {
        display: block;
        margin-left: 0;

        & > div:first-child {
          display: none;
        }
      }

      :global(.dropdown-area.hide-options) {
        & > a:first-child {
          font-weight: bold;
          font-size: 1.1rem;
          display: inline-block;
          margin-top: 1.3rem;
          pointer-events: none;
        }

        :global(.dropdown-options) {
          position: static;
          box-shadow: none;
          max-height: fit-content;
          background-color: transparent;
          line-height: 2em;
        }
      }
    }
  }

  :global(.primary-bar.mobile-close) {
    @media (max-width: $tablet-width) {
      padding: 0 30px;
    }
  }

  :global(.primary-bar.mobile-close .full-section-items) {
    @media (max-width: $mobile-width) {
      display: none;
    }
  }

  :global(.primary-bar .full-section-items) {
    position: relative;
    display: grid;
    grid-auto-flow: column;
    column-gap: 32px;
    justify-content: space-around;
    align-items: center;
    letter-spacing: 0.1em;
    font-size: 0.8rem;

    @media print {
      visibility: hidden;
    }

    svg {
      stroke: #777;
    }

    a {
      user-select: none;
    }

    :global(.mobile-close-link) {
      display: none;
      color: $accessible-gray;
    }

    :global(.styled-link) {
      color: #333;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      :global(.current) {
        font-weight: bold;
        color: $default-heading-color;
      }
    }

    :global(.special-options) {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 1em;
      align-items: center;
      margin-left: 3.5em;
    }

    :global(.dropdown-area) {
      position: relative;
      letter-spacing: normal;

      & > a:first-child {
        text-transform: uppercase;
      }

      & > a:first-child:hover {
        color: black;
        font-weight: bold;
        text-decoration: none;
      }

      :global(.dropdown-options) {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0 6px 6px 0px rgba(0, 0, 0, 0.08);
        line-height: 3em;
        z-index: 9999;
        transition: max-height 0.3s ease-out;
        overflow-y: hidden;
        font-size: 1rem;

        a {
          padding-right: 1em;
        }

        a:hover {
          color: #2ca7e4;
          background-color: #eee;
        }

        &:global(.align-left) {
          right: 0;
        }

        &:global(.small-screen-align-left) {
          @media only screen and (max-width: 1315px) {
            right: 0;
          }
        }
      }

      :global(.dropdown-arrow) {
        stroke: #777;
        transform: rotate(90deg);
        margin-left: 0.3rem;
      }
    }

    :global(.dropdown-area.show-options) {
      box-shadow: 0 6px 6px 0px rgba(0, 0, 0, 0.08);
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
      z-index: 1000;

      & > a:first-child {
        font-weight: bold;
      }

      & > :nth-child(2) {
        max-height: 432px;
      }
    }

    :global(.dropdown-area.hide-options) {
      & > :nth-child(2) {
        max-height: 0;
      }
    }
  }

  :global(.verified-limits-container) {
    width: 420px;
  }

  :global(.unverified-limits-container) {
    width: 520px;
  }

  :global(.nav-areas .usage-limits-notice) {
    font-size: 1rem;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1;
    line-height: 1rem;
    min-height: calc(30px + 1rem);
    grid-column: span 2;
    box-sizing: border-box;

    display: flex;
    column-gap: 1em;
    align-items: center;
    padding: 8px 18px;

    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #2ca7e4;
    color: white;

    @media (max-width: $tablet-width) {
      right: 15px;
    }

    @media (max-width: $mobile-width) {
      position: static;
      width: 100%;
      border-radius: 0;
      justify-content: space-around;

      :global(.allowance-text) {
        font-size: 0.9rem;
        text-wrap: nowrap;
      }

      :global(.info-tooltip) {
        display: none;
      }

      :global(.cta) {
        font-size: 0.6rem !important;
      }
    }

    @media print {
      visibility: hidden;
    }

    & > span {
      display: flex;
      align-items: center;
      column-gap: 0.5em;
    }

    a {
      color: white;
      text-decoration: none;
    }

    :global(.verification-link) {
      color: white;
      text-decoration: underline;
    }

    :global(.verification-link.disabled) {
      pointer-events: none;
      cursor: default;
    }

    :global(.cta) {
      font-size: 0.8rem;
      padding: 4px 12px;
      border: 1px solid white;
      border-radius: 12px;
      letter-spacing: 2px;
      text-transform: uppercase;

      &:hover {
        color: #2ca7e4;
        background-color: white;
      }
    }
  }

  :global(.login-ctas) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 16rem;
    line-height: 1.6rem;

    a {
      text-decoration: none;
      color: #777;
      text-transform: uppercase;
      letter-spacing: 2px;
    }

    a:hover {
      color: #2ca7e4;
    }

    & > :nth-child(2) {
      border: 1px solid #777;
      padding: 0.1rem 0.8rem;
      border-radius: 1rem;
    }

    & > :nth-child(2):hover {
      color: white;
      background-color: #2ca7e4;
    }

    :global(.links) {
      display: flex;
      column-gap: 1em;
      padding-right: 1em;
    }
  }

  :global(.meetings-container) {
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-width: 200px;
    align-items: center;
  }

  :global(.meetings-title) {
    font-size: 16px;
    font-weight: 600;
  }

  :global(.meetings-description) {
    font-size: 14px;
    text-align: center;
  }

  :global(.meetings-link) {
    font-size: 16px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.adminModal {
  color: black;
  padding: 24px 24px 40px;

  h2 {
    color: #2ca7e4;
    font-weight: 400;
  }

  :global(.header) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  :global(.close) {
    cursor: pointer;
  }

  :global(.pills) {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  :global(.pill-wrapper) {
    border: 1px solid #f5f5f5;
    border-radius: 3px;

    display: flex;
    align-items: center;
    column-gap: 1em;
    padding: 8px 24px;
  }
}
